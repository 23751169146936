import { Injectable } from '@angular/core';
import { WebService } from './web.service';

@Injectable({
  providedIn: 'root'
})
export class AtsService {

  constructor(
    private webService: WebService
  ) { }

  getRequistionList(options:any){
    return this.webService.getAts('requistion/',options);
  }

  getTemplateList(options:any ){
    return this.webService.getAts('templates',options);
  }

  getMasterList(){
    return this.webService.simpleGetAts('master/list');
  }

  addTemplate(payload:any){
    return this.webService.postAts('templates', payload)
  }

  updateTemplate(payload:any){
    console.log(payload)
    return this.webService.updateAts('templates/' + payload?.id, payload)
  }


  getTempDetails(id:any){
    return this.webService.simpleGetAts('templates/' + id);
  }

  addRequistion(payload:any){
    return this.webService.postAts('requistion/create', payload)
  }

  getDepartmentTemplate(id:any){
    return this.webService.simpleGetAts("templates/list-department-wise/" + id);
  }

  getSingleRequistion(id:any){
    return this.webService.simpleGetAts('requistion/' + id);
  }

  updateRequistion(payload:any){
    console.log(payload)
    return this.webService.updateAts('requistion/update/' + payload?.id, payload)
  }

  checkRequistion(payload:any){
    return this.webService.postAts('applicant/checkReqLink', payload)
  }

  applicantForm(payload:any){
    return this.webService.postAts('applicant/create', payload)
  }

  applicantList(options:any, id:any){
    return this.webService.getAts('applicant/list/' + id, options)
  }

  sendForReview(payload:any){
    return this.webService.postAts('applicant/review', payload)
  }

  reviewList(options:any, id:any){
    return this.webService.getAts('applicant/list/'+ id + '/reviewed', options)
  }

  sendForShorlist(payload:any){
    return this.webService.postAts('applicant/shortlist', payload)
  }

  shortListedApplicant(options:any, id:any){
    return this.webService.getAts('applicant/list/'+ id + '/shortlisted', options)
  }

  intervierwTemplateList(id:any){
    return this.webService.simpleGetAts('interview/list/'+id)
  }

  interviewShedule(payload:any){
    return this.webService.postAts('interview/schedule', payload)
  }

}
