<div class="info-fluid height100">
  <div class="card info_box p-15">
    <div class="logo-black bg-dark">
      <img src="assets/images/logo.png" alt="Logo Image">
    </div>
      <div class="row justify-content-center py-5">
          <div class="col-lg-4">
              <div class="job-view">
                  <div class="d-flex jobdtls-top">
                      <figure ><img alt="" src="https://dev14.ivantechnology.in/cartesian/public/no_logo.png" class="ng-star-inserted"></figure>
                      <div>
                      <p><span>ID: {{requistionData?.cId}}</span><span>Open Date: {{requistionData?.date | date:'yyyy-MM-dd'}}</span></p>
                      <h3>{{requistionData?.jobTitle}}</h3>
                      <h4> {{requistionData?.job_type | capitalizeFirstLetter}}</h4>
                      <!-- <p>testt</p> -->
                      </div>
                  </div>
                  <hr>
                  <h3 class="subhead">Job details</h3>
                  <div class="dtls-rpt">
                      <div class="d-flex align-items-center iconhead">
                          <img src="assets/images/watch.png" alt="">
                          <span>Job type</span>
                      </div>
                      <div class="lightgbtn"> {{requistionData?.job_type | capitalizeFirstLetter}} <i class="bi bi-check-lg"></i></div>
                  </div>

                  <div class="dtls-rpt">
                      <div class="d-flex align-items-center iconhead">
                          <img src="assets/images/watch.png" alt="">
                          <span>Shift</span>
                      </div>
                      <div class="lightgbtn"> {{requistionData?.shift | capitalizeFirstLetter}} <i class="bi bi-check-lg"></i></div>
                  </div>

                  <div class="dtls-rpt">
                      <div class="d-flex align-items-center iconhead">
                          <img src="assets/images/salary.png" alt="">
                          <span>Experience</span>
                      </div>
                      <div class="graybg"> {{requistionData?.experience}}&nbsp;Year </div>
                  </div>

                  <div class="dtls-rpt">
                      <div class="d-flex align-items-center iconhead">
                          <img src="assets/images/salary.png" alt="">
                          <span>Work Mode</span>
                      </div>
                      <div class="graybg"> {{requistionData?.work_mode}} </div>
                  </div>

                  <div class="dtls-rpt">
                      <div class="d-flex align-items-center iconhead">
                          <img src="assets/images/salary.png" alt="">
                          <span>Salary</span>
                      </div>
                      <div class="graybg"> {{requistionData?.salary}}&nbsp;AED </div>
                  </div>
                  <div class="dtls-rpt">
                      <div class="d-flex align-items-center iconhead">
                          <img src="assets/images/location.png" alt="">
                          <span>Job Location</span>
                      </div>
                      <p>{{requistionData?.job_location}}</p>
                  </div>
                  
                  <hr>
                  <h3 class="subhead mb-2">Job Description:</h3>
                  <div class="shortdesc" [innerHTML]="requistionData?.jobDescription">
                  
                  </div>
              </div>
          </div>
          <div class="col-lg-5">
              <div class="job-form">
                <h2 class="subhead mb-0">Application Form</h2>
                <form [formGroup]="recruitmentForm">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group position-relative ng-star-inserted labelwarp">
                        <label>First Name <span class="text-danger">*</span></label>
                        <input type="text" class="form-control static-text" formControlName="first_name">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(recruitmentForm, 'first_name', 'required')">This Field is Required</p>
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(recruitmentForm, 'first_name', 'pattern')">Enter Valid Input.</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group position-relative ng-star-inserted labelwarp">
                        <label>Last Name <span class="text-danger">*</span></label>
                        <input type="text" class="form-control static-text" formControlName="last_name">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(recruitmentForm, 'last_name', 'required')">This Field is Required</p>
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(recruitmentForm, 'last_name', 'pattern')">Enter Valid Input</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group position-relative ng-star-inserted labelwarp">
                        <label>Email <span class="text-danger">*</span></label>
                        <input type="email" class="form-control static-text" formControlName="email">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(recruitmentForm, 'email', 'required')">This Field is Required</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group position-relative ng-star-inserted labelwarp">
                        <label>Phone Number <span class="text-danger">*</span></label>
                        <input type="number" class="form-control static-text" formControlName="phone">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(recruitmentForm, 'phone', 'required')">This Field is Required</p>
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(recruitmentForm, 'phone', 'pattern')">Enter Valid Input</p>
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(recruitmentForm, 'phone', 'minLength')">Enter Valid Input</p>
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(recruitmentForm, 'phone', 'maxLength')">Enter Valid Input</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group position-relative ng-star-inserted labelwarp">
                        <label>Upload CV <span class="text-danger">*</span></label>
                        <input type="file" class="form-control" formControlName="cv" (change)="onFileUpload($event, recruitmentForm , 'cv', Global.DocumentType)">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(recruitmentForm, 'cv', 'required')">This Field is Required</p>
                        <p>Allowed file: pdf,zip,doc,docx, maximum upload file size: 5M</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group position-relative ng-star-inserted labelwarp">
                        <label>Profile Image</label>
                        <input type="file" class="form-control" formControlName="image" (change)="onFileUpload($event, recruitmentForm , 'image', Global.ImageType)">
                        <p>Max size: 5MB</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group position-relative ng-star-inserted labelwarp">
                        <label>Location <span class="text-danger">*</span></label>
                        <input type="text" class="form-control static-text" formControlName="location">
                        <p class="error-element" *ngIf="!Global.isInputRuleValid(recruitmentForm, 'location', 'required')">This Field is Required</p>
                        <!-- <p class="error-element" *ngIf="!Global.isInputRuleValid(recruitmentForm, 'first_name', 'pattern')">Enter Valid Input.</p> -->
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="apply-checkbox mb-3">
                         <re-captcha
                        siteKey="6Ldd-WgqAAAAAOzb8VEESrKPuAJ6wQ_SaRGahWFt"  
                        (resolved)="onCaptchaResolved($event)">
                      </re-captcha>
                      <!-- <p class="error-element" *ngIf="!Global.isInputRuleValid(recruitmentForm, 'captcha', 'required')">This Field is Required</p> -->
                      </div>
                      <button type="submit" class="btn button-solide static-text me-md-3 me-2" (click)="apply($event)">Apply Now</button>
                      <button type="reset" class="btn static-text button-solide gray-btn">Clear</button>
                    </div>
                  </div>
                </form>
              </div>
          </div>
      </div>
      <ul class="letter-footer d-flex justify-content-between">
        <li>
          <img src="assets/images/call.svg" alt="">
          <span>Phone No. :</span>
          <p>{{this.companyData?.establishment?.mobile_no ?? ""}}</p>
        </li>
        <li>
          <img src="assets/images/envelope.svg" alt="">
          <span>Email Id. :</span>
          <p>{{this.companyData?.establishment?.email_id ?? ""}}</p>
        </li>
        <li>
          <img src="assets/images/map.svg" alt="">
          <span>Address :</span>
          <p>{{this.companyData?.communication_office_address?.district_name ?? ""}},&nbsp; {{this.companyData?.communication_office_address?.state ?? ""}}</p>
        </li>
      </ul>
  </div>
</div>


